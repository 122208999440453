module.exports = {
  listIO: {
    data: [],
    loading: false,
  },
  JDIO: {
    data: {},
    loading: false,
  },
  SDIO: {
    data: {},
    loading: false,
  },
  classificationTabs: {
    ALL: {
      title: "All",
      tabId: "MEDICALNONMEDICAL",
      all: true,
      filter: true,
    },
    MEDICAL: {
      title: "Medical",
      tabId: "MEDICAL",
      lowerCaseValue: "medical",
      filter: true,
    },
    NONMEDICAL: {
      title: "Non-Medical",
      tabId: "NONMEDICAL",
      lowerCaseValue: "nonmedical",
      filter: true,
    },
    MEDICALNONMEDICAL: {
      title: "Medical and Non-Medical",
      tabId: "MEDICALNONMEDICAL",
    },
  },
  workOrderDashboardFilterTab: {
    TILLDATE: {
      title: "Till Date",
      tabId: "till_date",
      lowerCaseValue: "till date",
      filter: true
    },
    // DAILY: {
    //   title: "Daily",
    //   tabId: "daily",
    //   lowerCaseValue: "daily",
    //   filter: true,
    // },
    MONTHLY: {
      title: "Monthly",
      tabId: "monthly",
      lowerCaseValue: "monthly",
      filter: true,
    },
    YEARLY: {
      title: "Yearly",
      tabId: "yearly",
      lowerCaseValue: "yearly",
      filter: true,
    }
  },
  inProgressTabs: {
    ALL: {
      title: "All",
      tabId: "INPROGRESS",
      all: true,
      filter: true,
    },
    AWAITINGPARTS: {
      title: "Awaiting Parts",
      tabId: "AWAITINGPARTS",
      lowerCaseValue: "awaitingparts",
      filter: true,
    },
    HOLD: {
      title: "Hold",
      tabId: "used_by_patient_and_cannot_be_examined",
      lowerCaseValue: "hold",
      filter: true,
    },
  },
  woFilterTabs: {
    ALL: {
      title: "All",
      tabId: "MEDICALNONMEDICAL",
      all: true,
      filter: true,
    },
    MYWO: {
      title: "My WO",
      tabId: "MYWO",
      filter: true,
    },
  },
  woAssignTabs: {
    TECHNICIAN: {
      title: "Technician",
      tabId: "hmc_technician",
      all: true,
      filter: true,
    },
    PSP: {
      title: "3 PSP",
      tabId: "3psp",
      filter: true,
    },
    HMCSPECIAL: {
      title: "HMC Special",
      tabId: "hmc_special",
      filter: true,
    },
  },
  UI: { cardView: "card_view" },
  PAGE: {
    CURRENT: "page:current",
    PREVIOUS: "page:previous",
  },
  paginationObject: {
    to: 0,
    module: "",
    totalPages: 0,
    totalRecords: 0,
    pageCount: 0,
    pagination: {
      current_page: 1,
      per_page: 10,
    },
  },
  monthsList: [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" }
  ]
};
