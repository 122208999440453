import { SC } from "utility/helper";
import { history } from "utility/helper/history";
import { checkOTP } from "utility/helper/checkOTP";
import { resetListingData } from "redux/actions/renderList/renderListAction";
import { PAGE, UI } from "utility/helper/constants";
import { scrappingUserRole } from "utility/config";

export const loginWithJWT = (payload) => ({ type: "LOGIN_WITH_JWT", payload });
export const handleSites = (payload) => ({ type: "CHANGE_SITE", payload });

export const expireUser = (payload) => ({ type: "LOGOUT", payload });

export const getUser = () => async (dispatch) => {
  SC.getCall({ url: "/token_data" }).then((response) => {
    const responseCode = response.data.code;
    const OTPUsed = response.data.data?.OTPUsed;
    if (responseCode === 200) {
      console.log("token", response?.data?.data);
      dispatch(loginWithJWT(response?.data?.data));
      checkOTP(OTPUsed);
    } else {
      history.push("/login");
    }
  });
};

export const login = (data) => async (dispatch, getState) => {

  const { auth } = getState();

  const token = data?.data?.token;
  const OTPUsed = data?.data?.OTPUsed;
  const userRole = data?.data?.role;
  const isUnAuthRedirected = auth.login.intendedRoute && auth.login.intendedRoute === "unAuthorized";
  if (token) localStorage.setItem("token", token);
  dispatch(loginWithJWT(data?.data || {}));
  if (!OTPUsed) {
    checkOTP(OTPUsed);
  } else if (isUnAuthRedirected) {
    history.go(-1); // redirect me to last protected page i tried to visit
  } else if (
    userRole == "asset_supplier" ||
    userRole == "hospital_warehouse_manager" ||
    userRole == "hospital_equipment_engineer" ||
    userRole == "clinical_division_head" ||
    userRole == "hospital_director" ||
    userRole == "cluster_equipment_engineer" ||
    userRole == "cluster_head"
  ) {
    history.push("/asset-acceptance/list");
  } else if (
    userRole == "cluster_equipment_department" ||
    userRole == "cluster_contract_department_member" ||
    userRole == "cluster_contract_department_manager" ||
    userRole == "cluster_equipment_engineer" ||
    userRole == "cluster_equipment_committee_chairman" ||
    userRole == "hospital_maintenance_contractor" ||
    userRole == "hospital_biomedical_engineer" ||
    userRole == "head_of_moh_maintenance" ||
    userRole == "head_of_biomedical_moh_engineer" ||
    userRole == "hospital_maintenance_engineer" ||
    userRole == "head_of_department" ||
    userRole == "inventory_control"
  ) {
    history.push("/inventories");
  } else if (
    userRole == "3psp" ||
    userRole == "hmc_special" ||
    userRole == "hospital_head" ||
    userRole == "cluster_contract_head_of_department"
  ) {
    history.push("/work-orders/list");
  } else {
    history.push("/");
  }
};

export const logout = (props) => async (dispatch) => {
  // handle logout
  localStorage.removeItem("token");
  // ! remove path on logout action
  localStorage.removeItem(PAGE.PREVIOUS);
  localStorage.removeItem(PAGE.CURRENT);
  localStorage.removeItem(UI.cardView);
  dispatch(expireUser(props?.reason));
  dispatch(resetListingData({}));
  if (history.location.pathname !== "/login") history.push("/login");
};
