import { inventoryIcon, listIcon, scheduledIcon } from "assets/icons/svgIcons";
export default [
  {
    id: "inventory",
    title: "Inventory",
    icon: inventoryIcon,
    type: "collapse",
    pageTitle: "Inventory List",
    isActive: true,
    permissions: [
      "super_admin",
      "site_admin",
      "organization_admin",
      "staff",
      "engineer",
      "hmc_admin",
      "hmc_supervisor",
      "hmc_technician",
      "qa",
      "helpdesk",
      "call_center",
      "hospital_equipment_engineer",
      "hospital_biomedical_engineer",
      "cluster_equipment_department",
      "cluster_contract_department_member",
      "cluster_contract_department_manager",
      "cluster_equipment_engineer",
      "cluster_equipment_committee_chairman",
      "hospital_maintenance_contractor",
      "hospital_biomedical_engineer",
      "hospital_biomedical_engineer",
      "head_of_moh_maintenance",
      "head_of_biomedical_moh_engineer",
      "hospital_maintenance_engineer",
      "head_of_department",
      "hospital_warehouse_manager",
      "inventory_control",
    ],
    children: [
      {
        id: "inventoryList",
        title: "List",
        icon: listIcon,
        pageTitle: "Inventories List",
        navLink: "/inventories",
        type: "item",
        permissions: [
          "super_admin",
          "site_admin",
          "organization_admin",
          "staff",
          "engineer",
          "hmc_admin",
          "hmc_supervisor",
          "hmc_technician",
          "qa",
          "helpdesk",
          "call_center",
          "hospital_equipment_engineer",
          "hospital_biomedical_engineer",
          "cluster_equipment_department",
          "cluster_contract_department_member",
          "cluster_contract_department_manager",
          "cluster_equipment_engineer",
          "cluster_equipment_committee_chairman",
          "hospital_maintenance_contractor",
          "hospital_biomedical_engineer",
          "head_of_moh_maintenance",
          "head_of_biomedical_moh_engineer",
          "hospital_maintenance_engineer",
          "head_of_department",
          "hospital_warehouse_manager",
          "inventory_control",
        ],
      },
      {
        id: "inventoryPMCalender",
        title: "PM Calender",
        icon: scheduledIcon,
        pageTitle: "Preventive Maintenance Calender",
        navLink: "/inventories/pm-calender",
        type: "item",
        isWorkOrder: true,
        permissions: [
          "super_admin",
          "site_admin",
          "organization_admin",
          "staff",
          "engineer",
          "hmc_admin",
          "hmc_supervisor",
          "hmc_technician",
          "qa",
          "helpdesk",
          "call_center",
        ],
      },
    ],
  },
  {
    id: "inventory-upcoming",
    title: "Upcoming Out Of Warranty Assets",
    icon: inventoryIcon,
    type: "item",
    navLink: "/inventories-upcoming",
    pageTitle: "Upcoming Out Of Warranty Assets",
    permissions: ["super_admin"],
    isDisabledFromSideMenu: true,
  },
];
