import { FormattedMessage } from "react-intl";
import PrimaryButton from "../PrimaryButton";
import { env, projects } from "utility/config";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AscendLogo from "assets/img/logo/ascend-logo.png";
import MOHLogo from "assets/img/logo/moh-logo-new.svg";
import { siteLocationIcon } from "assets/icons/svgIcons";
import CloseModal from "assets/icons/close-circle-PMC.svg";

import { handleSites } from "redux/actions/auth/loginActions";
import { Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from "reactstrap";

export default function ClientLogo() {
  const dispatch = useDispatch();
  const [showModal, setShow] = useState(false);
  const [site, setSite] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { user, userExist, selectedSite } = useSelector((state) => ({
    user: state.auth.login.data,
    userExist: state.auth.login.data?._id,
    selectedSite: state.auth.login.selectedSite,
  }));

  useEffect(() => {
    if (userExist) {
      changeSite(user?.lab?._id);
    }
  }, [userExist]);

  const handleSwitch = (e) => {
    const object = user.sites.find((obj) => obj._id === site);
    dispatch(handleSites(object));
    handleClose();
    console.log(e);
  };

  const changeSite = (e) => {
    setSite(e);
  };

  const siteCount = user?.sites?.length;
  const siteLogo = user?.lab?.siteLogo;

  return (
    <>
      <div className="clientLogo d-none d-lg-flex">
        <div className="clientLogo__container">
          <h6>
            {env.PROJECT === projects.IMC ? (
              <FormattedMessage id={"Site Name"} defaultMessage={"Site Name"} />
            ) : env.PROJECT === projects.ASCEND_SERVICES ? (
              <FormattedMessage id={"Ascend Services"} defaultMessage={"Ascend Services"} />
            ) : (
              <FormattedMessage id={"Ministry of Health"} defaultMessage={"Ministry of Health"} />
            )}
          </h6>
          {(env.PROJECT === "enoviimax" &&
            (siteLogo ? (
              <div className="clientLogo__container__Logo">
                <img className="clientLogo__container__Logo--img" src={siteLogo} alt="" />
              </div>
            ) : (
              <div />
            ))) ||
            (env.PROJECT === projects.ASCEND_SERVICES && (
              <div className="clientLogo__container__Logo">
                <img className="clientLogo__container__Logo--img" src={AscendLogo} alt="" />
              </div>
            )) || (
              <div className="clientLogo__container__Logo">
                <img className="clientLogo__container__Logo--img" src={MOHLogo} alt="" />
              </div>
            )}
        </div>
        <div className="clientLogo__container__content" onClick={handleShow}>
          <div className="d-flex main-div justify-content-start">
            <span className="w-50 h-25">{siteLocationIcon}</span>
            <span className="clientLogo__container__content--count">{siteCount}</span>
          </div>
          <p id="site_name_logo_id">{selectedSite ? selectedSite?.site_name : user?.lab?.site_name}</p>
          <UncontrolledTooltip placement="bottom" target="site_name_logo_id">
            {
              <FormattedMessage
                id={selectedSite ? selectedSite?.site_name : user?.lab?.site_name}
                defaultMessage={selectedSite ? selectedSite?.site_name : user?.lab?.site_name}
              />
            }
          </UncontrolledTooltip>
        </div>
      </div>

      {/* ADD PART MODAL */}

      <Modal className="replacedParts-Modal sitesModal" isOpen={showModal}>
        <ModalHeader>
          <span>
            <FormattedMessage id={"Sites"} defaultMessage={"Sites"} />
          </span>
          <img src={CloseModal} alt="close" onClick={handleClose} className="click-able" />
        </ModalHeader>
        <ModalBody className="">
          {user?.sites?.map((siteObj, siteIndex) => {
            return (
              <SiteSelector key={siteIndex} {...siteObj} checked={siteObj?._id === site} changeSite={changeSite} />
            );
          })}
        </ModalBody>

        {/* <ModalFooter className={`${user?.sites?.length < 8 ? "no-scroll" : ""}`}>
          <PrimaryButton
            type="button"
            text="Cancel"
            onClick={handleClose}
            customClasses="red-outline border-1 pm-btn-w-50 box-shadow-none"
          />
          <PrimaryButton
            type="button"
            onClick={handleSwitch}
            customClasses="primary border-1 pm-btn-w-50 box-shadow-none"
            text={"Switch"}
          />
        </ModalFooter> */}
      </Modal>
    </>
  );
}

export const SiteSelector = (props) => {
  const handleChange = () => {
    props.changeSite(props?._id);
  };

  return (
    <div className="customRadioButton">
      {/* <input
        type="radio"
        id={`${props?.site_name}-${props?._id}`}
        className="radio-custom"
        name={"site"}
        value={props?._id}
        checked={props?.checked}
        onChange={handleChange}
      />
       */}
      {/* <label className={"radio-custom-label"} htmlFor={`${props?.site_name}-${props?._id}`}> */}
      <label className={"radio-custom-label"}>{props?.site_name}</label>
    </div>
  );
};
