import { listIcon, scheduledIcon, workOrderIcon, Overdue } from "assets/icons/svgIcons";

export default [
  {
    id: "workOrders",
    isWorkOrder: true,
    title: "Work Orders",
    icon: workOrderIcon,
    type: "collapse",
    // type: "item",
    // navLink: "/work-orders/list",
    // pageTitle: "Work Orders",
    isActive: true,
    permissions: [
      "super_admin",
      "site_admin",
      "organization_admin",
      "staff",
      "engineer",
      "hmc_admin",
      "hmc_supervisor",
      "hmc_technician",
      "hospital_head",
      "cluster_contract_head_of_department",
      "3psp",
      "hmc_special",
      "qa",
      "helpdesk",
      "call_center",
    ],
    children: [
      {
        id: "workOrderListAll",
        title: "All",
        pageTitle: "Work Orders",
        icon: listIcon,
        navLink: "/work-orders/list",
        type: "item",
        permissions: [
          "super_admin",
          "site_admin",
          "organization_admin",
          "staff",
          "engineer",
          "hmc_admin",
          "hmc_supervisor",
          "hmc_technician",
          "3psp",
          "hmc_special",
          "hospital_head",
          "cluster_contract_head_of_department",
          "qa",
          "helpdesk",
          "call_center",
        ],
      },
      {
        id: "workOrderCorrective",
        title: "corrective",
        pageTitle: "Work Orders - Corrective",
        icon: listIcon,
        navLink: "/work-orders/list/corrective",
        type: "item",
        permissions: [
          "super_admin",
          "site_admin",
          "organization_admin",
          "staff",
          "engineer",
          "hmc_admin",
          "hmc_supervisor",
          "hmc_technician",
          "3psp",
          "hmc_special",
          "hospital_head",
          "cluster_contract_head_of_department",
          "qa",
          "helpdesk",
          "call_center",
        ],
      },
      {
        id: "workOrderPreventive",
        title: "preventive",
        pageTitle: "Work Orders - Preventive",
        icon: scheduledIcon,
        navLink: "/work-orders/list/preventive",
        type: "item",
        permissions: [
          "super_admin",
          "site_admin",
          "organization_admin",
          "staff",
          "engineer",
          "hmc_admin",
          "hmc_supervisor",
          "hmc_technician",
          "qa",
          "helpdesk",
          "call_center",
        ],
      },
      {
        id: "workOrderOverdue",
        title: "Overdue",
        pageTitle: "Work Orders - Overdue",
        icon: Overdue,
        navLink: "/work-orders/list/overdue",
        type: "item",
        permissions: [
          "super_admin",
          "site_admin",
          "organization_admin",
          "staff",
          "engineer",
          "hmc_admin",
          "hmc_supervisor",
          "hmc_technician",
          "qa",
          "helpdesk",
          "call_center",
        ],
      },
    ],
  },
];
